<template>
    <div>
        <div class="event">
            <b-container class="event__container event__interactive-only">
                <div class="event__grid">
                    <div class="event__section-interactive event__grid-item">
                        <al-card style="height: 100%" no-padding overflow-hidden>
                            <al-dii-wrapper
                                v-if="event"
                                :email="getEmail"
                                :name="getFullName"
                                :context_id="event?.id"
                                :with-auth="true"
                                :room="diiRoomId"
                            />
                            <!--              <iframe-->
                            <!--                  v-if="event"-->
                            <!--                  width="100%"-->
                            <!--                  height="100%"-->
                            <!--                  min-height="300px"-->
                            <!--                  scrolling="no"-->
                            <!--                  border-radius="10px"-->
                            <!--                  style="border:none"-->
                            <!--                  :src="iframeURL"-->
                            <!--                  allow="microphone *; camera *; speaker;" allowfullscreen></iframe>-->
                        </al-card>
                    </div>
                </div>
            </b-container>
        </div>
    </div>
</template>
<script>
import AlCard from '@/components/AlCard';
import OnAirIcon from '@/components/icons/OnAirIcon';
import DeviceMixin from '@/mixins/deviceMixin';
import AlBtn from '@/components/AlBtn';
import ConstantsMixin from '@/mixins/constantsMixin';
import { mapGetters } from 'vuex';
import EventApi from '@/services/event';
import AlDiiWrapper from '@/components/AlDiiWrapper';

export default {
    name: 'EventOffline',
    mixins: [DeviceMixin, ConstantsMixin],
    components: { OnAirIcon, AlCard, AlBtn, AlDiiWrapper },
    props: {
        eventId: { type: String | Number },
    },

    data() {
        return {
            event: null,
        };
    },
    async mounted() {
        await this.fetchEventData();
    },

    computed: {
        ...mapGetters('auth', ['getEmail', 'getFullName']),

        iframeURL() {
            const baseURL = new URL(this.event.link_dii ?? 'https://dii.center-game.com/stream_screen.php?UID=1');

            baseURL.searchParams.append('email', this.getEmail);
            baseURL.searchParams.append('context_id', this.event.id);
            baseURL.searchParams.append('name', this.getFullName);

            return baseURL.toString();
        },

        diiRoomId() {
            if (this.event && this.event.link_dii) {
                return this.event.link_dii.includes('https://') ? 1 : this.event.link_dii;
            }
            return 1;
        },
    },

    methods: {
        async fetchEventData() {
            const [err, result] = await EventApi.getEventById(this.$route.params.eventId);
            if (err) {
                await this.$router.push({
                    name: 'main',
                });
            }

            this.event = result;
        },
    },
};
</script>
